import en from '~/translations/json/en.json'
import fr from '~/translations/json/fr.json'
import it from '~/translations/json/it.json'
import hr from '~/translations/json/hr.json'
import pt from '~/translations/json/pt.json'
import cs from '~/translations/json/cs.json'
import nl from '~/translations/json/nl.json'
import pl from '~/translations/json/pl.json'
import de from '~/translations/json/de.json'
import es from '~/translations/json/es.json'

export default defineI18nConfig(() => ({
  legacy: false,
  allowComposition: true,
  globalInjection: true,
  fallbackLocale: 'en',
  messages: {
    en,
    fr,
    it,
    hr,
    pt,
    cs,
    nl,
    pl,
    de,
    es,
  },
}))
